import React from "react";
import { graphql } from "gatsby";
import LayoutYd from "../components/layoutYd";
import "./newsy.less";

const NewsItem = (props) => {
  let item = props.item;
  // let index = props.index;
  let imgUrl = (item.node.featured_media && item.node.featured_media.source_url) || "";
  return (
    <dl className='newsItemYd'>
      <dt><img src={imgUrl} alt='' /></dt>
      <dd>
        <h4 className='newsTitle'><div dangerouslySetInnerHTML={{ __html: item.node.title }}/></h4>
        <div className='newsContentYd'>
          <div className='content'>
            <div dangerouslySetInnerHTML={{ __html: item.node.excerpt }}/>
          </div>
        </div>
        <label>{item.node.modified}</label>
      </dd>
    </dl>
  );
};

const NewsY = (props) => {
  const data = props.data.allWordpressPost.edges;

  return (
    <LayoutYd>
      <div className='outBoxYd'>
        <div className='boxYd'>
          {
            data.map((item, index) => {
              return (
                <a href={item.node.link} target='_blank' rel="noopener noreferrer" key={`newsy${item.node.id}`}>
                  <NewsItem item={item} index={index}/>
                </a>
              );
            })
          }
        </div>
      </div>
    </LayoutYd>
  );
};

export const newsQueryY = graphql`
    query newsQueryY {
        allWordpressPost( sort: { fields: [ modified ], order: DESC } ){
    edges {
        node {
            id
            title
            slug
            modified( formatString: "YYYY.MM.DD" )
            link
		content
		excerpt
		featured_media {
        source_url
      }
             
        } 
    }
}
}    
`;
export default NewsY;

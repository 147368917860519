import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import Message from './message/message';
import "./headerYd.less";
import { isInWindow, currentUrlToActiveTab, getTerminalType } from "../utils/tools";
import { WebTitle } from "../utils/constant";

class HeaderYd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: "home",
      fold: true,
      isShowMessage:false
    };
  }

  componentWillMount() {
    if (isInWindow()) {
      document.title = WebTitle.title;
      let terminalType = getTerminalType();
      let urlArr = window.location.pathname.split("/");
      let temp = [];
      // eslint-disable-next-line
      urlArr.filter((item) => {
        if (item) {
          temp.push(item);
        }
      });
      let tempIndex0 = temp[0];
      if (tempIndex0.charAt(tempIndex0.length - 1) === "y" && terminalType === "pc") {//pc设备打开移动页面
        let t = tempIndex0.substring(0, tempIndex0.length - 1);
        temp[0] = t + "p";
        window.location.href = "/" + temp.join("/");
      }
    }
  }

  componentDidMount() {
    if (isInWindow()) {
      let url = window.location.href;
      let activeItem = `${currentUrlToActiveTab(url)}`;
      this.setState({
        activeItem: activeItem
      });
    }
  }

  willDone(item,e) {
    switch (item) {
      case "home":
        this.navigatorTo(item);
        break;
      case "teacher":
        this.navigatorTo(item);
        break;
      case "master":
        this.navigatorTo(item);
        break;
      case "operation":
        e.preventDefault();
        this.showAlert();
        break;
      case "news":
        this.navigatorTo(item);
        break;
      case "download":
        this.navigatorTo(item);
        break;
      default:
        this.navigatorTo("404");
    }
  }

  navigatorTo(toUrl) {
    this.setState({
      activeItem: toUrl,
      fold: true
    });
  }

  showAlert() {
    this.setState({
      fold: true,
      isShowMessage:true
    });

    setTimeout(()=>{
      this.setState({
        isShowMessage:false
      })
    },3000);
  }

  changeActiveItem() {
    this.setState({
      fold: !this.state.fold
    });
  }

  closeMenuBox() {
    this.setState({
      fold: true
    });
  }

  render() {
    let activeItem = this.state.activeItem;
    let tempPos = !this.state.fold ? "fixed" : "";
    if (isInWindow()) {
      document.getElementsByTagName("body")[0].style.position = tempPos;
    }
    return (
      <div className='headerYd'>
        <Helmet>
          <meta content="yes" name="apple-mobile-web-app-capable"/>
          <meta name="viewport"
                content="width=device-width,height=device-height,inital-scale=1.0,maximum-scale=1.0,user-scalable=no;"/>
        </Helmet>
        <div className='leftHeader'>
          <label/>
        </div>
        <div className='rightHeader' onClick={this.changeActiveItem.bind(this)}>
          <label/>
        </div>
        {
          !this.state.fold &&
          <div className='menuBox' onClick={this.closeMenuBox.bind(this)}>
            <ul className='menu'>
              <li>
                <Link to='/homey' key={'home'} className={activeItem === "home" ? "active" : "normal"}
                      onClick={this.willDone.bind(this, "home")}>首页</Link>
              </li>
              <li>
                <Link to='/teachery' key={'teacher'} className={activeItem === "teacher" ? "active" : "normal"}
                      onClick={this.willDone.bind(this, "teacher")}>我是老师</Link>
              </li>
              <li>
                <Link to='/mastery' key={'master'} className={activeItem === "master" ? "active" : "normal"}
                      onClick={this.willDone.bind(this, "master")}>我是校长</Link>
              </li>
              <li>
                <Link to='' key={'operation'} className={activeItem === "operation" ? "active" : "normal"}
                      onClick={this.willDone.bind(this, "operation")}>申请合作</Link>
              </li>
              <li>
                <Link to='/newsy' key={'newsy'} className={activeItem === "news" ? "active" : "normal"}
                      onClick={this.willDone.bind(this, "news")}>新闻中心</Link>
              </li>
              <li>
                <Link to='/downloady' key={'download'} className={activeItem === "download" ? "active" : "normal"}
                      onClick={this.willDone.bind(this, "download")}>下载中心</Link>
              </li>
            </ul>
          </div>
        }
        {
          this.state.isShowMessage && <Message info="内容正在维护，敬请期待！"/>
        }
      </div>
    );
  }
}


export default HeaderYd;
